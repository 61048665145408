:root {
  --drop-height: 135px;
  --logo-size: 150px;
  --offset: 80px;
  --duration: 0.7s;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    position: absolute;
    top: var(--offset);
    left: calc(50% - var(--logo-size) * 1.45); 
    animation-name: App-logo-bounce;
    animation-duration: var(--duration);
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    animation-iteration-count: infinite;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-bounce {
  from { 
    transform: translateY(0) scale(1);
  }
  to   { 
    transform: translateY(var(--drop-height)) scale(1, 0.7);
  }
}
